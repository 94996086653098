import React from 'react';
import { PagerButton } from '~/components/Button/Pager';
import { PagerArrow } from '~/components/Button/PagerArrow';
import { Pager } from '../styled';
export function ArticlePager({ page, maxPage, onClick }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Pager, null,
            React.createElement(PagerArrow, { direction: "left", onClick: () => {
                    if (page === 1)
                        return;
                    onClick(page - 1);
                } }),
            Array.from({ length: maxPage }).map((_, index) => {
                return React.createElement(PagerButton, { active: page === index + 1, index: index + 1, key: 'pagerButton_' + index, onClick: onClick });
            }),
            React.createElement(PagerArrow, { direction: "right", onClick: () => {
                    if (page === maxPage)
                        return;
                    onClick(page + 1);
                } }))));
}
