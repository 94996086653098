import styled from '@emotion/styled';
export const CompanyInvestmentCompanyAndPartnersBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '188px 0px 100px 0px',
    objectFit: 'contain',
    backgroundColor: '#f3f6f6',
    fontFamily: 'SpoqaHanSansNeo',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
});
export const CompanyInvestmentCompanyAndPartnersTitle = styled('div')({
    fontSize: 42,
    width: '80%',
    margin: '0 auto 60px auto',
    fontWeight: 'bold',
    textAlign: 'center',
});
export const InvestmentCompanyAndPartnersRow = styled('div')({
    margin: '0 auto 0 auto',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 700px)': {
        width: '100%',
        display: 'block',
        margin: '0 auto 0 auto',
    },
});
export const Layout = styled('div')({
    padding: '160px 160px',
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    lineHeight: '137%',
    backgroundColor: '#F1F1F1',
    '@media screen and (max-width: 720px)': {
        padding: '80px 16px',
    },
});
export const Title = styled('h1')({
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: '137%',
    color: '#000000',
    margin: 0,
    span: {
        color: '#29AD37',
    },
    '@media screen and (max-width: 720px)': {
        fontSize: '24px',
    },
});
