import styled from '@emotion/styled';
export const Layout = styled('div')({
    padding: '160px 152px',
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: '137%',
    '@media screen and (max-width: 720px)': {
        padding: '60px 16px',
        fontSize: '24px',
        lineHeight: '137%',
    },
});
export const Title = styled('h1')({
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: '137%',
    color: '#000000',
    margin: 0,
    span: {
        color: '#29AD37',
    },
    '@media screen and (max-width: 720px)': {
        fontSize: '24px',
    },
});
export const KeyMapLayout = styled('div')({
    display: 'flex',
    margin: 'auto',
    gap: '100px',
    width: '100%',
    flexDirection: 'column',
    fontFamily: 'Pretendard',
    letterSpacing: '-0.035em',
    color: '#000000',
});
export const KeyMapTitle = styled('h3')({
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '125%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    padding: '0 30px 0 0 ',
    letterSpacing: '-0.035em',
    '@media screen and (max-width: 720px)': {
        fontSize: '24px',
    },
});
export const KeyMapDescription = styled('h4')({
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '150%',
    margin: 0,
    alignSelf: 'flex-start',
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
    letterSpacing: '-0.05em',
    '@media screen and (max-width: 720px)': {
        fontSize: '16px',
        whiteSpace: 'pre-line',
    },
});
export const Divider = styled('div')({
    width: '100%',
    border: '0.5px solid #CDCDCD',
    height: '0px',
    margin: 'auto',
    padding: '0 34px 0 0',
    '@media screen and (max-width: 720px)': {
        margin: '8px 0 16px 0',
        padding: 0,
    },
});
