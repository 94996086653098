import styled from '@emotion/styled';
import React, { useState } from 'react';
const ArrowButton = styled('div')({
    borderRadius: 999,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    width: '32px',
    aspectRatio: '1 / 1',
    height: '32px',
    cursor: 'pointer',
    '@media screen and (max-width: 720px)': {
        width: '40px',
        height: '40px',
    },
});
export const PagerArrow = ({ direction, onClick }) => {
    const [isHover, setIsHover] = useState(false);
    return (React.createElement(ArrowButton, { style: {
            backgroundColor: isHover ? '#D0D0D0' : '#F1F1F1',
        }, onClick: onClick, onMouseOver: () => setIsHover(true), onMouseLeave: () => setIsHover(false) },
        React.createElement("img", { src: "/img/main/pager/ic_left_arrow_black.svg", alt: "arrow_button", style: {
                rotate: direction === 'right' ? '180deg' : '0deg',
                width: '8px',
                margin: 'auto',
            } })));
};
