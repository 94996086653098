import styled from '@emotion/styled';
export const EntrepreneurList = styled('ul')({
    listStyle: 'none',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingInlineStart: 0,
    border: '1px solid #e5e5e5',
});
export const EntrepreneurListItem = styled('li')({
    borderBottom: '1px solid #e5e5e5',
    padding: '12px',
    '& > div:first-child': {
        fontWeight: 'bold',
        marginBottom: '6px',
    },
});
export const EntrepreneurDescription = styled('p')({
    lineHeight: '150%',
    padding: '10px',
});
