import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
export const BannerImageBox = styled('img')({
    width: '100vw',
    display: 'block',
    height: '100vh',
    margin: 0,
    objectFit: 'cover',
});
export const BannerMainTitle = styled('span')({
    textAlign: 'center',
    position: 'absolute',
    color: '#FFFFFF',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    letterSpacing: '-0.02em',
    fontSize: '56px',
    fontWeight: 800,
    lineHeight: '150%',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    '@media screen and (max-width: 720px)': {
        fontSize: '24px',
        lineHeight: '135%',
    },
});
const bounceAnimation = keyframes `
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;
export const BottomArrowAnimation = styled('img')({
    position: 'absolute',
    left: 'calc( 50% - 16.5px )',
    transform: 'translateX(-50%)',
    bottom: 33,
    animation: `${bounceAnimation} 1s infinite`,
});
