import React from 'react';
import { Layout, Title } from '~/components/Main/CompanyInvestmentCompanyAndPartners/styled';
import { useGetViewPort } from '~/hooks/useGetViewPort';
import { SponsorBox, SponsorLine } from '../CompanyNews/styled';
const MainCompanyInvestmentCompanyAndPartners = () => {
    const [isMobile] = useGetViewPort();
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, null,
            React.createElement(Title, null,
                "\uD568\uAED8\uD558\uACE0 \uC788\uB294",
                React.createElement("br", null),
                React.createElement("span", null, "\uD22C\uC790\uC790 & \uC81C\uD734/\uD30C\uD2B8\uB108\uC27D")),
            React.createElement(SponsorBox, null, isMobile ? (React.createElement(React.Fragment, null,
                React.createElement(SponsorLine, null,
                    React.createElement("img", { src: "/img/main/partners/the-wells-invest-s.svg", alt: "the-wells-invest" }),
                    React.createElement("img", { src: "/img/main/partners/DB-insure-s.svg", alt: "DB-insure" }),
                    React.createElement("img", { src: "/img/main/partners/DGB-fin-s.svg", alt: "DGB-fin" })),
                React.createElement(SponsorLine, null,
                    React.createElement("img", { src: "/img/main/partners/codef-s.svg", alt: "codef" }),
                    React.createElement("img", { src: "/img/main/partners/kca-s.svg", alt: "the-wells-invest" }),
                    React.createElement("img", { src: "/img/main/partners/kyobo-insure-s.svg", alt: "kyobo-insure" })),
                React.createElement(SponsorLine, null,
                    React.createElement("img", { src: "/img/main/partners/IBK-s.svg", alt: "IBK" }),
                    React.createElement("img", { src: "/img/main/partners/daejeon-s.svg", alt: "daejeon" }),
                    React.createElement("img", { src: "/img/main/partners/aiin-s.svg", alt: "aiin" })),
                React.createElement(SponsorLine, null,
                    React.createElement("img", { src: "/img/main/partners/cnt-s.svg", alt: "the-wells-invest" }),
                    React.createElement("img", { src: "/img/main/partners/kiwoong-s.svg", alt: "DB-insure" }),
                    React.createElement("img", { src: "/img/main/partners/kor-fin-s.svg", alt: "DGB-fin" })),
                React.createElement(SponsorLine, null,
                    React.createElement("img", { src: "/img/main/partners/ckd-s.svg", alt: "codef" }),
                    React.createElement("img", { src: "/img/main/partners/front-one-s.svg", alt: "front-one" }),
                    React.createElement("img", { src: "/img/main/partners/posco-s.svg", alt: "posco" })),
                React.createElement(SponsorLine, null,
                    React.createElement("img", { src: "/img/main/partners/my-sync-s.svg", alt: "my-sync" }),
                    React.createElement("img", { src: "/img/main/partners/krypton-s.svg", alt: "krypton" }),
                    React.createElement("img", { src: "/img/main/partners/coocon-s.svg", alt: "coocon" })))) : (React.createElement(React.Fragment, null,
                React.createElement(SponsorLine, null,
                    React.createElement("img", { src: "/img/main/partners/the-wells-invest.svg", alt: "the-wells-invest" }),
                    React.createElement("img", { src: "/img/main/partners/DB-insure.svg", alt: "DB-insure" }),
                    React.createElement("img", { src: "/img/main/partners/DGB-fin.svg", alt: "DGB-fin" }),
                    React.createElement("img", { src: "/img/main/partners/codef.svg", alt: "codef" })),
                React.createElement(SponsorLine, null,
                    React.createElement("img", { src: "/img/main/partners/kca.svg", alt: "the-wells-invest" }),
                    React.createElement("img", { src: "/img/main/partners/kyobo-insure.svg", alt: "kyobo-insure" }),
                    React.createElement("img", { src: "/img/main/partners/IBK.svg", alt: "IBK" }),
                    React.createElement("img", { src: "/img/main/partners/daejeon.svg", alt: "daejeon" }),
                    React.createElement("img", { src: "/img/main/partners/aiin.svg", alt: "aiin" })),
                React.createElement(SponsorLine, null,
                    React.createElement("img", { src: "/img/main/partners/cnt.svg", alt: "the-wells-invest" }),
                    React.createElement("img", { src: "/img/main/partners/kiwoong.svg", alt: "DB-insure" }),
                    React.createElement("img", { src: "/img/main/partners/kor-fin.svg", alt: "DGB-fin" }),
                    React.createElement("img", { src: "/img/main/partners/ckd.svg", alt: "codef" }),
                    React.createElement("img", { src: "/img/main/partners/front-one.svg", alt: "front-one" })),
                React.createElement(SponsorLine, null,
                    React.createElement("img", { src: "/img/main/partners/posco.svg", alt: "posco" }),
                    React.createElement("img", { src: "/img/main/partners/my-sync.svg", alt: "my-sync" }),
                    React.createElement("img", { src: "/img/main/partners/krypton.svg", alt: "krypton" }),
                    React.createElement("img", { src: "/img/main/partners/coocon.svg", alt: "coocon" }))))))));
};
export default MainCompanyInvestmentCompanyAndPartners;
