import React from 'react';
import MainBanner from '~/components/Main/Banner';
import MainCompanyGrowth from '~/components/Main/CompanyGrowth';
import { CompanyHistory as MainCompanyHistory } from '~/components/Main/CompanyHistory';
import MainCompanyInvestmentCompanyAndPartners from '~/components/Main/CompanyInvestmentCompanyAndPartners';
import MainCompanyNews from '~/components/Main/CompanyNews';
import { CompanyMap as MainCompanyMap } from './CompanyMap';
import { CompanyTech as MainCompanyTech } from './CompanyTech';
import { CompanyVision as MainCompanyVision } from './CompanyVision';
const Main = () => {
    return (React.createElement("div", { id: "0" },
        React.createElement(MainBanner, null),
        React.createElement(MainCompanyVision, null),
        React.createElement(MainCompanyGrowth, null),
        React.createElement(MainCompanyTech, null),
        React.createElement(MainCompanyHistory, null),
        React.createElement(MainCompanyNews, null),
        React.createElement(MainCompanyInvestmentCompanyAndPartners, null),
        React.createElement(MainCompanyMap, null)));
};
export default Main;
