import styled from '@emotion/styled';
export const CompanyHistoryBox = styled('div')({
    '@media screen and (max-width: 720px)': {
        padding: '60px 0px 0px 0px',
    },
    display: 'flex',
    flexDirection: 'column',
    padding: '188px 0px 188px 0px',
    objectFit: 'contain',
    backgroundColor: '#f6f6f6',
    fontFamily: 'SpoqaHanSansNeo',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
});
export const CompanyHistoryTitle = styled('span')({
    '@media screen and (max-width: 720px)': {
        fontSize: 24,
        margin: '0 auto 30px auto',
    },
    fontSize: 42,
    width: '80%',
    margin: '0 auto 100px auto',
    fontWeight: 'bold',
    letterSpacing: -0.4,
    textAlign: 'left',
    color: '#000',
});
export const Divider = styled('div')({
    margin: '0 auto 60px auto',
    width: '80%',
    height: 2,
    borderRadius: 999,
    backgroundColor: '#000',
});
export const HistoryRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto 0 auto',
    width: '80%',
    '& span': {
        '@media screen and (max-width: 720px)': {
            fontSize: 16,
        },
        fontSize: 24,
    },
    '.title': {
        color: '#777',
    },
    '& div': {
        display: 'flex',
        flexDirection: 'column',
        '& span': {
            marginBottom: 24,
        },
        '& span:nth-last-of-type(1)': {
            marginBottom: '64px',
        },
    },
});
export const Layout = styled('div')({
    padding: '80px 160px',
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    lineHeight: '137%',
    '@media screen and (max-width: 720px)': {
        padding: '40px 16px',
    },
});
export const Title = styled('h1')({
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: '137%',
    color: '#000000',
    margin: 0,
    span: {
        color: '#29AD37',
    },
    '@media screen and (max-width: 720px)': {
        fontSize: '24px',
    },
});
export const Panel = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    '@media screen and (max-width: 720px)': {
        marginTop: '40px',
        gap: '32px',
    },
});
export const PanelHeader = styled('div')({
    padding: '0 160px',
    display: 'flex',
    gap: '43px',
    borderBottom: '1px solid #CDCDCD',
    '@media screen and (max-width: 720px)': {
        gap: '28px',
        padding: '0 16px',
        fontSize: '32px',
        overflowX: 'scroll',
    },
});
export const PanelContent = styled('div')({
    display: 'flex',
    gap: '40px',
    padding: '48px 0 120px 256px',
    flexDirection: 'column',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '137%',
    letterSpacing: '-0.02em',
    borderBottom: '1px solid #F1F1F1',
    fontFamily: 'Pretendard',
    '@media screen and (max-width: 720px)': {
        padding: '0 16px 60px 16px',
        fontSize: '16px',
    },
});
export const PanelDescription = styled('div')({
    width: '448px',
});
export const PanelDescTitle = styled('span')({
    letterSpacing: '-0.05em',
    fontSize: '32px',
    lineHeight: '150%',
    fontWeight: 700,
    color: '#000000',
});
export const PanelDescText = styled('span')({
    letterSpacing: '-0.05em',
    fontSize: '20px',
    lineHeight: '150%',
    fontWeight: 400,
    color: '#000000',
});
