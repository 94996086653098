import styled from '@emotion/styled';
export const Layout = styled('div')({
    padding: '160px 160px',
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    lineHeight: '137%',
    a: {
        cursor: 'pointer',
        color: 'inherit',
        textDecoration: 'none',
    },
    '@media screen and (max-width: 720px)': {
        padding: '80px 16px',
    },
});
export const Title = styled('h1')({
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: '137%',
    color: '#000000',
    margin: 0,
    span: {
        color: '#29AD37',
    },
    '@media screen and (max-width: 720px)': {
        fontSize: '24px',
    },
});
export const MapContainer = styled('div')({
    display: 'flex',
    gap: '32px',
    padding: '80px 96px 64px 96px',
    '@media screen and (max-width: 720px)': {
        padding: '32px 0',
        flexDirection: 'column',
    },
});
export const MapContacts = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: '0 96px 80px 96px',
    '@media screen and (max-width: 720px)': {
        padding: '8px 0',
    },
});
export const MapContactSubject = styled('span')({
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '137%',
    letterSpacing: '-0.02em',
    color: '#B5B5B5',
    '@media screen and (max-width: 720px)': {
        fontSize: '14px',
    },
});
export const MapContactContent = styled('a')({
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '-0.9px',
    color: '#000000',
    '@media screen and (max-width: 720px)': {
        fontSize: '16px',
    },
});
