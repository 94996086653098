import React from 'react';
import { createRoot } from 'react-dom/client';
import ErrorBoundary from '~/components/Error/Boundary';
import PagesRouter from '~/pages/Router';
const Index = () => (React.createElement(React.Suspense, { fallback: "loading..." },
    React.createElement(ErrorBoundary, null,
        React.createElement(PagesRouter, null))));
const container = document.getElementById('root');
const root = createRoot(container);
root.render(React.createElement(Index, null));
