import React from 'react';
import { BannerImageBox, BannerMainTitle, BottomArrowAnimation } from '~/components/Main/Banner/styled';
import { useGetViewPort } from '~/hooks/useGetViewPort';
const MainBanner = () => {
    const [isMobile] = useGetViewPort();
    return (React.createElement("div", { style: { maxWidth: '100%' } },
        React.createElement(BannerMainTitle, null,
            "\uB9C8\uC774\uB370\uC774\uD130\uB97C \uAE30\uBC18\uC73C\uB85C",
            React.createElement("br", null),
            "\uBCF4\uD5D8\uC0B0\uC5C5\uC758 \uD601\uC2E0\uC744 \uB9AC\uB4DC\uD569\uB2C8\uB2E4."),
        React.createElement(BannerImageBox, { src: isMobile ? 'img/main/banner/main_mobile.png' : 'img/main/banner/main.png', srcSet: "", alt: "main_banner_image" }),
        React.createElement(BottomArrowAnimation, { src: "/img/main/icon/bottom_arrow.svg", alt: "bottom_arrow.svg" })));
};
export default MainBanner;
