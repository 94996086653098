import React, { useState } from 'react';
import { StoreButton } from '~/components/Button/store';
import { useGetViewPort } from '~/hooks/useGetViewPort';
import { Layout, Panel, PanelContent, PanelDescText, PanelDescTitle, PanelDescription, PanelHeader, Title } from './styled';
export function CompanyTech() {
    const [PanelTab, setPanelTab] = useState('B2B');
    const [isMobile] = useGetViewPort();
    return (React.createElement(Layout, { id: "2" },
        React.createElement(Title, null,
            "\uBCF4\uD5D8\uC0B0\uC5C5\uC5D0\uC11C",
            React.createElement("br", null),
            "\uADF8\uB9B0\uB9AC\uBCF8\uB9CC\uC758\u00A0",
            React.createElement("span", null, "\uAE30\uC220"),
            "\uB85C \uC774\uB04C\uC5B4\uAC11\uB2C8\uB2E4."),
        React.createElement(Panel, null,
            React.createElement(PanelHeader, null,
                React.createElement("div", { style: {
                        color: PanelTab === 'B2B' ? '#000000' : '#B5B5B5',
                        cursor: 'pointer',
                        fontSize: isMobile ? '32px' : '40px',
                        fontWeight: '700',
                        lineHeight: '125%',
                        letterSpacing: '-0.035em',
                        textDecoration: PanelTab === 'B2B' ? 'underline' : 'none',
                    }, onClick: () => setPanelTab('B2B') }, "B2B"),
                React.createElement("div", { style: {
                        color: PanelTab === 'B2C' ? '#000000' : '#B5B5B5',
                        cursor: 'pointer',
                        fontSize: isMobile ? '32px' : '40px',
                        fontWeight: '700',
                        lineHeight: '125%',
                        letterSpacing: '-0.035em',
                        textDecoration: PanelTab === 'B2C' ? 'underline' : 'none',
                    }, onClick: () => setPanelTab('B2C') }, "B2C")),
            React.createElement(PanelContent, { style: {
                    gap: PanelTab === 'B2B' ? (isMobile ? '24px' : '109px') : '32px',
                } }, PanelTab === 'B2B' ? (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement("img", { src: "/img/main/tech/b2b.svg", alt: "b2b_img", style: {
                            maxWidth: '466px',
                            maxHeight: '390px',
                            width: isMobile ? '272px' : '466px',
                            height: isMobile ? '228px' : '390px',
                        } })),
                React.createElement(PanelDescription, null,
                    React.createElement("div", { style: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                        } },
                        React.createElement(PanelDescTitle, null, "\uAE30\uC5C5\uC6A9 \uC11C\uBE44\uC2A4"),
                        React.createElement(PanelDescText, null,
                            "\uBAA8\uB4E0 \uBCF4\uD5D8\uC0AC\uB4E4\uC774 AI \uAE30\uC220\uC744 \uD65C\uC6A9\uD560 \uC218 \uC788\uB3C4\uB85D",
                            React.createElement("br", null),
                            " SaaS\uD615 \uC194\uB8E8\uC158\uC744 \uC81C\uACF5\uD558\uC5EC \uC774\uB97C \uD1B5\uD574 \uD1B5\uD569 \uB370\uC774\uD130 \uD559\uC2B5",
                            React.createElement("br", null),
                            "\uC804\uB7B5\uC744 \uAE30\uBC18\uC73C\uB85C \uC9C0\uAE09\uC2EC\uC0AC(\uC704\uBCC0\uC870, \uC720\uBCD1\uB825), \uC5B8\uB354\uB77C\uC774\uD305 \uB4F1\uC758",
                            React.createElement("br", null),
                            "\uBD84\uC57C\uC5D0\uC11C \uBCF4\uB2E4 \uBE60\uB978 \uC758\uC0AC\uACB0\uC815\uC744 \uB3C4\uC640\uC8FC\uAC70\uB098",
                            React.createElement("br", null),
                            "\uC5C5\uBB34\uB97C \uC790\uB3D9\uD654 \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4."))))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { style: {
                        display: 'flex',
                        gap: isMobile ? '20px' : '32px',
                        padding: isMobile ? '30px 0' : '40px 64px',
                    } },
                    React.createElement("img", { src: "/img/main/tech/b2c_1.svg", alt: "b2c_img", style: {
                            width: isMobile ? '127px' : '237px',
                            height: isMobile ? '257px' : '480px',
                        } }),
                    React.createElement("img", { src: "/img/main/tech/b2c_2.svg", alt: "b2c_img", style: {
                            width: isMobile ? '127px' : '237px',
                            height: isMobile ? '257px' : '480px',
                        } })),
                React.createElement(PanelDescription, null,
                    React.createElement("div", { style: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        } },
                        React.createElement("div", { style: {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                            } },
                            React.createElement(PanelDescTitle, null, "\uB77C\uC774\uD504\uCE90\uCE58"),
                            React.createElement(PanelDescText, null,
                                "\uBC14\uC05C \uD604\uB300\uC778\uB4E4\uC744 \uC704\uD574\uC11C\uB958 \uBC1C\uAE09\uBD80\uD130",
                                React.createElement("br", null),
                                "\uBCF4\uD5D8\uAE08 \uCCAD\uAD6C \uB300\uD589\uAE4C\uC9C0 \uB193\uCE5C \uBCF4\uD5D8\uAE08\uC744",
                                React.createElement("br", null),
                                "\uB300\uC2E0 \uCC3E\uC544\uC8FC\uB294 \uBCF4\uD5D8 \uCCAD\uAD6C \uB300\uD589 \uD50C\uB7AB\uD3FC\uC785\uB2C8\uB2E4.")),
                        React.createElement("div", { style: {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '38px',
                            } },
                            React.createElement("div", null,
                                React.createElement("a", { href: "https://lifecatch.co.kr", target: "_blank", style: {
                                        fontWeight: 500,
                                        fontSize: isMobile ? '14px' : '16px',
                                        lineHeight: '150%',
                                        letterSpacing: '-0.05em',
                                        color: '#29AD37',
                                        display: 'flex',
                                        gap: '7px',
                                        textDecoration: 'none',
                                        border: isMobile ? '1px solid #29AD37' : 'none',
                                        padding: isMobile ? '9px 27px 11px 27px' : '0',
                                        borderRadius: '24px',
                                        width: 'fit-content',
                                    } },
                                    "\uD648\uD398\uC774\uC9C0\uB85C \uC774\uB3D9",
                                    React.createElement("img", { src: "/img/main/tech/half_right_green_arrow.svg", alt: "half_right_green_arrow.svg", style: {
                                            width: '12px',
                                            height: '6px',
                                            margin: 'auto 0',
                                        } }))),
                            React.createElement("div", { style: {
                                    display: 'flex',
                                    gap: '32px',
                                } },
                                React.createElement(StoreButton, { storeType: "google" }),
                                React.createElement(StoreButton, { storeType: "apple" })))))))))));
}
