import styled from '@emotion/styled';
export const LifeCatchFooterArea = styled('div')({
    width: '100%',
    display: 'flex',
    backgroundColor: '#F8F8F8',
});
export const LifeCatchFooterContainer = styled('div')({
    width: '100%',
    display: 'flex',
    margin: '0 auto',
    flexDirection: 'column',
    padding: '16px 0px 24px 12px',
    '@media screen and (min-width: 620px)': {
        width: '76%',
        padding: '32px 0px 24px 12px',
    },
});
export const InfoTapContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '10px',
    fontWeight: '500',
});
export const Division = styled('div')({
    border: '1px solid #eeeeee',
    height: '9px',
    margin: 'auto 8px auto 8px',
    '@media screen and (max-width: 1200px)': {
        height: '16px',
        border: 'none',
    },
});
export const LifeCatchInfoDescTitleContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});
export const GreenRibbonName = styled('div')({
    fontSize: '14px',
    fontWeight: '400',
    margin: 'auto 0px auto 0px',
    color: '#000000',
});
export const SelectInfoDescImg = styled('img')({
    height: '25px',
    width: '24px',
    margin: 'auto 0px auto 8px',
});
export const InfoDescContainer = styled('div')({
    fontSize: '12px',
    color: '#777777',
    lineHeight: '18px',
    fontWeight: '500',
});
export const InfoDescCeoAndCorporateRegistrationNumber = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});
export const InfoTapItem = styled('div')({
    cursor: 'pointer',
    margin: 'auto 0',
});
export const LifeCatchInfoDetail = styled('div')({
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: '#ffffff',
    zIndex: 999,
    overflowY: 'scroll',
    overflowX: 'hidden',
    '@media screen and (min-width: 680px)': {
        width: '100%',
        margin: '0px auto',
    },
});
export const LifeCatchInfoDetailContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
});
export const LifeCatchInfoDetailTitleContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '56px',
    borderBottom: '1px solid #dddddd',
});
export const LifeCatchInfoDetailTitleDesc = styled('div')({
    fontSize: '18px',
    fontWeight: '800',
    margin: 'auto 0px auto 0px',
    padding: '0px 27.5px 0px 27.5px',
});
export const LifeCatchInfoDetailTitleBalanceDiv = styled('div')({
    width: '18px',
    height: '18px',
    margin: 'auto 18px auto 18px',
});
export const LifeCatchInfoDetailTitleCloseButtonImg = styled('img')({
    width: '18px',
    height: '18px',
    margin: 'auto 18px auto 18px',
});
export const LifeCatchInfoDescContainer = styled('div')({
    padding: '24px 11px 24px 12px',
    fontSize: '10px',
});
export const LifeCatchInfoDescDetailContainer = styled('div')({
    paddingLeft: '5px',
});
export const InformationAboutEntrepreneurContainer = styled('div')({
    padding: '16px',
    borderBottom: '1px solid #bbbbbb',
    lineHeight: '24px',
});
export const InformationAboutTitle = styled('div')({
    fontSize: '16px',
    fontWeight: '800',
});
export const InformationAboutDesc = styled('div')({
    fontSize: '16px',
});
export const InformationAboutNotification = styled('div')({
    padding: '25px 14px 75px 24px',
    backgroundColor: '#f6f6f6',
    fontSize: '12px',
    lineHeight: '18px',
});
export const FooterDialog = styled('dialog')({
    '&[open]': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});
export const FooterDialogCloseButton = styled('button')({
    alignSelf: 'flex-end',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    margin: '14px',
});
