import React from 'react';
import { EntrepreneurDescription, EntrepreneurList, EntrepreneurListItem } from './styled';
const informationAboutEntrepreneurAgreementList = [
    {
        title: '사업자등록번호',
        desc: ['377-86-01897'],
    },
    {
        title: '법인여부',
        desc: ['법인'],
    },
    {
        title: '상호',
        desc: ['주식회사 그린리본'],
    },
    {
        title: '대표자명',
        desc: ['김규리'],
    },
    {
        title: '판매방식',
        desc: ['인터넷'],
    },
    {
        title: '취급품목',
        desc: ['기타'],
    },
    {
        title: '신고일자',
        desc: ['2021. 09. 13'],
    },
    {
        title: '사업장소재지',
        desc: ['대전 유성구 대학로 157, 디-브릿지 디1동 공유오피스-4'],
    },
    // {
    //   title: '사업장소재지(도로명)',
    //   desc: ['대전광역시 유성구 유성대로 1689번길 70, 연구1동', '1층 대전사관학교 (전민동, KT대덕2연구센타)'],
    // },
    {
        title: '인터넷도메인',
        desc: ['www.lifecatch.co.kr'],
    },
    {
        title: '호스트서버소재지',
        desc: ['-'],
    },
];
export const EntrepreneurPageModal = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(EntrepreneurList, null, informationAboutEntrepreneurAgreementList.map((item) => (React.createElement(EntrepreneurListItem, { key: item.title },
            React.createElement("div", null, item.title),
            React.createElement("div", null, item.desc.map((value) => (React.createElement("div", { key: value }, value)))))))),
        React.createElement(EntrepreneurDescription, null,
            "\uBCF8\uC790\uB8CC\uB294 \uC804\uC790\uC0C1\uAC70\uB798\uC2DC\uC7A5\uC5D0\uC11C \uC18C\uBE44\uC790\uAC00 \uC815\uD655\uD55C \uC0AC\uC5C5\uC790",
            React.createElement("br", null),
            "\uC815\uBCF4\uB97C \uAC00\uC9C0\uACE0 \uC548\uC804\uD55C \uAC70\uB798\uB97C \uD560 \uC218 \uC788\uB3C4\uB85D \uC804\uAD6D \uC2DC, \uAD70, \uAD6C\uC5D0",
            React.createElement("br", null),
            "\uC2E0\uACE0\uB41C \uD1B5\uC2E0\uD310\uB9E4\uC5C5\uC790\uC758 \uC2E0\uC6D0\uC815\uBCF4\uB97C \uC804\uC790\uC0C1\uAC70\uB798 \uC18C\uBE44\uC790 \uBCF4\uD638\uBC95",
            React.createElement("br", null),
            "\uC81C12\uC8704\uD56D\uC5D0 \uB530\uB77C \uC81C\uACF5\uD558\uB294 \uC815\uBCF4\uC785\uB2C8\uB2E4. \uC0AC\uC5C5\uC790 \uC815\uBCF4\uC5D0 \uB300\uD55C",
            React.createElement("br", null),
            "\uAD81\uAE08\uD55C \uC0AC\uD56D\uC774\uB098 \uC0AC\uC5C5\uC790\uC758 \uC2E0\uC6D0\uC815\uBCF4\uAC00 \uC815\uBCF4\uACF5\uAC1C \uB0B4\uC6A9\uACFC",
            React.createElement("br", null),
            "\uBD88\uC77C\uCE58\uD560 \uACBD\uC6B0\uC5D0\uB294 \uC0AC\uC5C5\uC790 \uC815\uBCF4\uAC80\uC0C9 \uC2DC \uD655\uC778\uB418\uB294 \uD574\uB2F9 \uC2E0\uACE0\uAE30\uAD00",
            React.createElement("br", null),
            "(\uC9C0\uBC29\uC790\uCE58\uB2E8\uCCB4)\uC5D0 \uBB38\uC758\uD558\uC5EC \uC8FC\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.",
            React.createElement("br", null),
            React.createElement("br", null),
            "\uC77C\uBD80 \uC0AC\uC5C5\uC790\uC758 \uACBD\uC6B0, \uBD80\uAC00\uAC00\uCE58\uC138\uBC95\uC0C1 \uC0AC\uC5C5\uC790 \uD3D0\uC5C5 \uC2E0\uACE0\uB294",
            React.createElement("br", null),
            "\uD558\uC600\uC73C\uB098 \uC804\uC790\uC0C1\uAC70\uB798\uBC95\uC0C1 \uD1B5\uC2E0\uD310\uB9E4\uC5C5 \uD3D0\uC5C5 \uC2E0\uACE0\uB294 \uD558\uC9C0 \uC54A\uB294",
            React.createElement("br", null),
            "\uC0AC\uB840\uAC00 \uC788\uC744 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uC18C\uBE44\uC790 \uD53C\uD574\uB97C \uBC29\uC9C0\uD558\uAE30 \uC704\uD574 \uBD80\uAC00\uAC00",
            React.createElement("br", null),
            "\uCE58\uBC95\uC0C1 \uC0AC\uC5C5\uC790 \uD3D0\uC5C5 \uC5EC\uBD80\uB3C4 \uAD6D\uC138\uCCAD \uD648\uD0DD\uC2A4\uD398\uC774\uC9C0(www.hometax.go.kr)",
            React.createElement("br", null),
            "\uC758 \uC0AC\uC5C5\uC790\uB4F1\uB85D\uC0C1\uD0DC\uC870\uD68C \uCF54\uB108\uB97C \uD1B5\uD574 \uD655\uC778\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.")));
};
