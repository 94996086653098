import React, { useState } from 'react';
import { ArticleSection, Layout, Title } from '~/components/Main/CompanyNews/styled';
import { ArticlePager } from './ArticlePager';
import { NewsArticle } from './NewsArticle';
const NewsArticleList = [
    {
        link: 'https://www.itbiznews.com/news/articleView.html?idxno=132460',
        title: '㈜그린리본, 금융위원회 지정대리인, 위탁 테스트 우수사례 선정',
        origin: 'IT비즈뉴스 | 2024.04.26',
    },
    {
        link: 'https://www.hankyung.com/article/2024032418901',
        title: '시중은행 전환 추진하는 DGB대구은행…핀테크 스타트업 키운다',
        origin: '한국경제 | 2024.03.24',
    },
    {
        link: 'https://www.thebigdata.co.kr/view.php?ud=2024031513111012939aeda69934_23',
        title: "라이프캐치' 누적 가입자 수 100만 명 넘어서",
        origin: '빅데이터뉴스 | 2024.03.15',
    },
    {
        link: 'https://www.fnnews.com/news/202403141106515051',
        title: '"금융사기 일 평균 2만건 예방" 금융위, 금융회사-핀테크 협력 우수사례집 발간',
        origin: '파이낸셜뉴스 | 2024.03.14',
    },
    {
        link: 'https://www.itbiznews.com/news/articleView.html?idxno=124896',
        title: '그린리본, 교보증권으로부터 Pre-A2라운드 20억 투자 유치',
        origin: 'IT비즈뉴스 | 2024.02.01.',
    },
    {
        link: 'https://www.insight.co.kr/news/458400',
        title: '한해 사라지는 보험금만 7천억...내가 받을 수 있는 병원비 환급금 찾아주는 앱의 정체',
        origin: '인사이트 | 2023.12.28.',
    },
    {
        link: 'https://www.psnews.co.kr/news/articleView.html?idxno=2039768',
        title: "우리은행, 그린리본과 '놓친 보험금 찾기' 서비스 출시",
        origin: '퍼블릭뉴스 | 2023.11.29.',
    },
    {
        link: 'https://www.job-post.co.kr/news/articleView.html?idxno=89812',
        title: '그린리본, 미청구 보험금 찾아주는 ‘라이프캐치’ 선보여',
        origin: '잡포스트 | 2023.11.24.',
    },
    {
        link: 'https://www.itbiznews.com/news/articleView.html?idxno=118715',
        title: "병원비 환급금이 이만큼?… '라이프캐치', 미청구 보험금 찾아주는 서비스 제공",
        origin: 'IT비즈뉴스 | 2023.11.24.',
    },
    {
        link: 'https://www.thebigdata.co.kr/view.php?ud=2023111313215149629aeda69934_23',
        title: "㈜그린리본, 놓친 보험금 조회 '라이프캐치' 가입자 수 80만명 달성",
        origin: '빅데이터뉴스 | 2023.11.13.',
    },
    {
        link: 'https://www.epnc.co.kr/news/articleView.html?idxno=238016',
        title: "라이프캐치, 병원 서류 발급 가능한 ‘서류발급 대행' 기능 추가",
        origin: '테크월드뉴스 | 2023.10.31.',
    },
    {
        link: 'https://www.siminilbo.co.kr/news/newsview.php?ncode=1160293683513186',
        title: '그린리본, 3분기 놓친 보험금 3,790억 발굴',
        origin: '시민일보 | 2023.10.20.',
    },
    {
        link: 'https://www.itbiznews.com/news/articleView.html?idxno=112369',
        title: '인슈어테크 기업 그린리본 브랜드 라이프캐치, 추석 퀴즈 이벤트 실시',
        origin: 'IT비즈뉴스 | 2023.09.28.',
    },
    {
        link: 'http://www.kdpress.co.kr/news/articleView.html?idxno=122437',
        title: '라이프캐치, MZ세대가 찾아간 놓친 보험금 16억 돌파',
        origin: '데일리경제 | 2023.09.12.',
    },
    {
        link: 'https://www.itbiznews.com/news/articleView.html?idxno=111264',
        title: '라이프캐치, 입크 페스티벌(IBK FESTIVAL) 참가 성료',
        origin: 'IT비즈뉴스 | 2023.09.19.',
    },
    {
        link: 'https://www.epnc.co.kr/news/articleView.html?idxno=236253',
        title: '그린리본, ‘코리아 핀테크 위크 2023’ 참가 성료 ',
        origin: '테크월드뉴스 | 2023.09.05.',
    },
    {
        link: 'https://www.e2news.com/news/articleView.html?idxno=256948',
        title: '그린리본, ‘라이프캐치’ 놓친 보험금 대행청구 서비스 개편',
        origin: '이투뉴스 | 2023.08.17.',
    },
    {
        link: 'http://www.kdpress.co.kr/news/articleView.html?idxno=121807',
        title: "그린리본, ‘라이프캐치' 내 가족 연동 서비스 오픈",
        origin: '데일리경제 | 2023.08.14.',
    },
    {
        link: 'https://www.ajunews.com/view/20230703143534128',
        title: '라이프캐치, 여름 휴가비 최대 100만원 지원 이벤트',
        origin: '아주경제 | 2023.07.03.',
    },
    {
        link: 'https://www.seoul.co.kr/news/economy/2023/06/27/20230627500119',
        title: '그린리본, 라이프캐치 앱 리뉴얼 출시 ‘보상 서비스 직관성 개선’',
        origin: '서울신문 | 2023.06.28.',
    },
    {
        link: 'https://www.koit.co.kr/news/articleView.html?idxno=114385',
        title: '라이프캐치, 가입자 60만 육박…미청구 보험금 누적 조회액은 5800억 넘어',
        origin: '정보통신신문 | 2023.06.27.',
    },
    {
        link: 'https://news.mt.co.kr/mtview.php?no=2023061212380531188',
        title: "그린리본, '2023 넥스트라이즈'서 간편한 보험청구대행 서비스 '라이프캐치' 소개",
        origin: '머니투데이 | 2023.06.14.',
    },
    {
        link: 'https://m.dailian.co.kr/news/view/1211141',
        title: '그린리본, 더웰스인베스트먼트 등 19억 투자 유치',
        desc: `인공지능 알고리즘 기반 보험금 청구 자동화 서비스 ‘라이프캐치’를 제공하는
        그린리본이 더웰스인베스트먼트, CKD창업투자, 크립톤,...`,
        origin: '데일리안ㅣ 2023.03.13.',
    },
    {
        link: 'https://news.mt.co.kr/mtview.php?no=2022102116414840572',
        title: '그린리본, 라이프캐치 이용자 20만명 달성',
        desc: `그린리본(대표 김규리)은 보험 청구 대행플랫폼 ‘라이프캐치’가 이용자수 20만명을
        돌파했다...`,
        origin: '머니투데이ㅣ 2022.10.21.',
    },
    {
        link: 'https://www.newscj.com/news/articleView.html?idxno=1022421',
        title: '[종합] ‘라이프캐치’ 캐시워크 돈버는퀴즈 정답 ‘OOOO억원’',
        desc: `돈버는퀴즈 [천지일=박혜옥 기자] 19일 ‘라이프캐치 묵혀둔 병원비 환급’ 관련 캐시워크
        돈버...`,
        origin: '천지일보ㅣ 2022.09.19.',
    },
    {
        link: 'https://www.etnews.com/20221103000043',
        title: '그린리본, 라이프캐치 이용자 30만명 달성',
        desc: '그린리본(대표 김규리)은 자사 서류 발급 비용 합리화 서비스 라이프캐치 이용자 수가 30만명으로 집계됐다고 3일 밝혔다. 실비보험 청구...',
        origin: '전자신문ㅣ 2022.11.03.',
    },
    {
        link: 'https://news.mt.co.kr/mtview.php?no=2022102116414840572',
        title: '그린리본, 라이프캐치 이용자 20만명 달성',
        desc: '그린리본(대표 김규리)은 보험 청구 대행 플랫폼 ‘라이프캐치’가 이용자수 20만 명을 돌파했다고 밝혔다. 오롯이 고객 만족을 위한 서비스...',
        origin: '머니투데이ㅣ 2022.10.21.',
    },
    {
        link: 'https://kr.aving.net/news/articleView.html?idxno=1772579',
        title: '그린리본, KES 2022서 보험금 청구대행 플랫폼 ‘라이프캐치’알려...',
        desc: '그린리본은 10월 4일(화)부터 7일(금)까지 서울 코엑스에서 개최되는 ‘KES 2022(제 53회 한국전자전)’에 참가해 ‘라이프캐치’를 소개했...',
        origin: '에이빙뉴스ㅣ 2022.10.05.',
    },
    {
        link: 'http://www.koreastocknews.com/news/articleView.html?idxno=74464',
        title: '보험금 조회 서비스 라이프캐치, 투약 관리 업데이트',
        desc: '그린리본은 보험금 조회 서비스 ‘라이프캐치’를 통해 처방 내역을 연결해 투약 내역을 확인할 수 있는 투약 관리 업데이트를 진행했다고 1...',
        origin: '증권경제신문ㅣ 2022.09.16.',
    },
    {
        link: 'http://www.goodkyung.com/news/articleView.html?idxno=186953',
        title: '실비보험청구대행 라이프캐치, 숨은 보험금 랭킹 서비스 도입',
        desc: '그린리본은 실비보험 청구 간소화 서비스인 라이프캐치에 숨은 보험금 랭킹 서비스, 투약 서비스 등을 전격 도입하며 앱과 웹에서 업데이...',
        origin: '증굿모닝경제ㅣ 2022.09.07.',
    },
    {
        link: 'https://news.mt.co.kr/mtview.php?no=2022062912202336500',
        title: '라이프캐치, 모바일,웹 페이지 개선...종합 보상 플랫폼 도약 준비',
        desc: '(주)그린리본은 다양한 라이프캐치 서비스와 예상 미청구 보험금 조회, 청구대행 서비스 등을 한눈에 볼 수 있도록 첫 화면을 전면 개편한...',
        origin: '머니투데이ㅣ 2022.06.29.',
    },
    {
        link: 'https://news.mt.co.kr/mtview.php?no=2022052413221684482',
        title: '라이프캐치, 미청구 보험금 조회 인원 14만 명 돌파',
        desc: '소멸시효 3년 이내 미청구 보험금을 한 번에 찾을 수 있는 서비스를 제공하는 인슈어테크 스타트업 그린리본(대표 김규리)은 라이프캐치...',
        origin: '머니투데이ㅣ 2022.05.24.',
    },
];
const MainCompanyNews = () => {
    const [page, setPage] = useState(1);
    const handleClick = (number) => {
        number !== 0 && setPage(number);
    };
    const maxPage = Math.ceil(NewsArticleList.length / 4);
    return (React.createElement(Layout, { id: "4" },
        React.createElement(Title, null,
            React.createElement("span", null, "\uC5B8\uB860"),
            "\uC5D0\uC11C",
            React.createElement("br", null),
            "\uB9D0\uD558\uB294 \uADF8\uB9B0\uB9AC\uBCF8"),
        React.createElement(ArticleSection, null, NewsArticleList.map((news, index) => {
            if (index >= (page - 1) * 4 && index < page * 4) {
                return React.createElement(NewsArticle, { key: index, link: news.link, origin: news.origin, title: news.title });
            }
            return null;
        })),
        React.createElement(ArticlePager, { maxPage: maxPage, onClick: handleClick, page: page })));
};
export default MainCompanyNews;
