import React, { useEffect, useState } from 'react';
import { HeaderBox, HeaderContainer, HeaderLinkSection, HeaderLogo, MobileMenu, MobileMenuBox, MobileMenuBoxHeader, MobileMenuList, } from '~/components/Header/styled';
import { useGetViewPort } from '~/hooks/useGetViewPort';
const Header = () => {
    const [headerBackground, setHeaderBackground] = useState('transparent');
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile] = useGetViewPort();
    useEffect(() => {
        const handleScroll = () => {
            window.scrollY > 88 ? setHeaderBackground('#FFFFFF') : setHeaderBackground('transparent');
            window.scrollY > 88 ? setHeaderBackground('#FFFFFF') : setHeaderBackground('transparent');
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (React.createElement(HeaderContainer, null,
        React.createElement(HeaderBox, { style: {
                backgroundColor: headerBackground,
                transition: 'background-color 0.3s ease-out',
                borderBottom: headerBackground === '#FFFFFF' ? '1px solid #F0F0F0' : '',
            } },
            React.createElement("a", { href: "/" },
                React.createElement(HeaderLogo, { src: headerBackground === 'transparent' ? 'img/header/header_logo.svg' : 'img/header/header_logo_active.svg' })),
            isMobile ? (React.createElement(React.Fragment, null,
                React.createElement("img", { src: headerBackground === 'transparent' ? 'img/header/white_hamburger.svg' : 'img/header/hamburger.svg', alt: "hamburgerMenu", style: {
                        width: '20px',
                        height: '18px',
                        cursor: 'pointer',
                    }, onClick: () => setIsOpen(true) }))) : (React.createElement(HeaderLinkSection, null,
                React.createElement("a", { href: "#0" }, "\uADF8\uB9B0\uB9AC\uBCF8"),
                React.createElement("a", { href: "#1" }, "\uBAA9\uD45C"),
                React.createElement("a", { href: "#2" }, "\uC0AC\uC5C5"),
                React.createElement("a", { href: "#3" }, "\uC5F0\uD601"),
                React.createElement("a", { href: "#4" }, "\uB274\uC2A4"),
                React.createElement("a", { href: "#5" }, "\uBB38\uC758")))),
        isOpen ? (React.createElement(MobileMenuBox, null,
            React.createElement(MobileMenuBoxHeader, null,
                React.createElement("img", { src: "/img/header/x-button.svg", alt: "hamburgerMenu", style: {
                        width: '18px',
                        height: '18px',
                        margin: '0 0 0 auto',
                        cursor: 'pointer',
                    }, onClick: () => setIsOpen(false) })),
            React.createElement(MobileMenuList, { onClick: () => setIsOpen(false) },
                React.createElement(MobileMenu, { href: "#0" }, "\uADF8\uB9B0\uB9AC\uBCF8"),
                React.createElement(MobileMenu, { href: "#1" }, "\uBAA9\uD45C"),
                React.createElement(MobileMenu, { href: "#2" }, "\uC0AC\uC5C5"),
                React.createElement(MobileMenu, { href: "#3" }, "\uC5F0\uD601"),
                React.createElement(MobileMenu, { href: "#4" }, "\uB274\uC2A4"),
                React.createElement(MobileMenu, { href: "#5" }, "\uBB38\uC758")))) : null));
};
export default Header;
