import React, { useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
function PdfViewer({ path }) {
    const [page, setPage] = useState(1);
    function onDocumentLoadSuccess({ numPages }) {
        setPage(numPages);
    }
    const width = useMemo(() => {
        const innerWidth = window.innerWidth || 0;
        return Math.min(innerWidth, 480);
    }, []);
    return (React.createElement(Document, { file: path, onLoadSuccess: onDocumentLoadSuccess }, Array.from(new Array(page), (el, index) => (React.createElement(Page, { key: `page_${index + 1}`, pageNumber: index + 1, renderAnnotationLayer: false, renderTextLayer: false, width: width })))));
}
export default PdfViewer;
