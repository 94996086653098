import React, { useState } from 'react';
import { Article, ArticleOrigin, ArticleTitle } from '../styled';
export function NewsArticle({ title, origin, link }) {
    const [isHover, setIsHover] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement("a", { href: link, target: "_blank" },
            React.createElement(Article, { onMouseOver: () => setIsHover(true), onMouseLeave: () => setIsHover(false) },
                React.createElement(ArticleTitle, { style: {
                        color: isHover ? '#29AD37' : '#000000',
                    } }, title),
                React.createElement(ArticleOrigin, null, origin)))));
}
