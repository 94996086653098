import React from 'react';
import { useGetViewPort } from '~/hooks/useGetViewPort';
import { Divider, KeyMapDescription, KeyMapLayout, KeyMapTitle, Layout, Title } from './styles';
export const CompanyVision = () => {
    const [isMobile] = useGetViewPort();
    return (React.createElement(Layout, { id: "1" },
        React.createElement(Title, null,
            "\uADF8\uB9B0\uB9AC\uBCF8\uC740",
            React.createElement("br", null),
            React.createElement("span", null, "\uBAA9\uD45C"),
            "\uB97C \uAC00\uC9C0\uACE0 \uC6C0\uC9C1\uC785\uB2C8\uB2E4."),
        React.createElement("div", { style: {
                display: 'flex',
                padding: isMobile ? '0' : '120px 0 0 0',
                maxWidth: '736px',
                margin: 'auto',
            } },
            React.createElement("div", { style: {
                    width: '100%',
                    padding: isMobile ? '32px 16px' : '0 30px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: isMobile ? '32px' : '100px',
                } },
                React.createElement(KeyMapLayout, null,
                    React.createElement("div", { style: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: isMobile ? 'column' : 'row',
                        } },
                        React.createElement(KeyMapTitle, null, "Vision"),
                        React.createElement(Divider, null),
                        isMobile ? (React.createElement(KeyMapDescription, null,
                            "\uBCF4\uD5D8\uC744 \uB458\uB7EC\uC2FC \uC18C\uBE44\uC790\uC758 'Needs'\uB97C \uD574\uC18C\uD558\uACE0,",
                            React.createElement("br", null),
                            " \uB9C8\uC774\uB370\uC774\uD130\uB97C \uAE30\uBC18\uC73C\uB85C \uBCF4\uD5D8\uC0B0\uC5C5\uC758 \uD601\uC2E0\uC744 \uC774\uB8E8\uB294",
                            React.createElement("br", null),
                            " No 1. \uC778\uC288\uC5B4 \uD14C\uD06C \uAE30\uC5C5.")) : null)),
                React.createElement(KeyMapLayout, null,
                    React.createElement("div", { style: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: isMobile ? 'column' : 'row',
                        } },
                        React.createElement(KeyMapTitle, null, "Value"),
                        React.createElement(Divider, null),
                        isMobile ? (React.createElement(KeyMapDescription, null,
                            "\uC2DC\uB3C4\uC640 \uC2E0\uB8B0\uB97C \uAC00\uCE58\uB85C \uB0B4\uAC78\uACE0 \uD601\uC2E0\uC744 \uC704\uD55C \uB04A\uC784\uC5C6\uB294 \uC2DC\uB3C4\uC640",
                            React.createElement("br", null),
                            " \uBCF4\uD5D8\uC0B0\uC5C5\uC5D0\uC11C\uC758 \uC2E0\uB8B0\uB97C \uB9CC\uB4E4\uC5B4\uAC00\uB294 \uC5ED\uD560.")) : null)),
                React.createElement(KeyMapLayout, null,
                    React.createElement("div", { style: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: isMobile ? 'column' : 'row',
                        } },
                        React.createElement(KeyMapTitle, null, "Mission"),
                        React.createElement(Divider, null),
                        isMobile ? (React.createElement(KeyMapDescription, null,
                            "\uADF8\uB9B0\uB9AC\uBCF8\uC744 \uD1B5\uD574 \uAE0D\uC815\uC801\uC774\uACE0 \uAC00\uCE58\uC788\uB294 \uACBD\uD5D8\uC744 \uC81C\uACF5\uD558\uBA70,",
                            React.createElement("br", null),
                            "\uBCF4\uD5D8\uC808\uCC28\uC758 \uAC04\uC18C\uD654\uC640 \uB514\uC9C0\uD138\uD654\uC758 \uBBF8\uB798\uC131\uC7A5\uC744 \uC120\uB3C4.")) : null))),
            isMobile ? null : (React.createElement("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '100px',
                } },
                React.createElement(KeyMapLayout, null,
                    React.createElement(KeyMapDescription, null,
                        "\uBCF4\uD5D8\uC744 \uB458\uB7EC\uC2FC \uC18C\uBE44\uC790\uC758 'Needs'\uB97C \uD574\uC18C\uD558\uACE0,",
                        React.createElement("br", null),
                        " \uB9C8\uC774\uB370\uC774\uD130\uB97C \uAE30\uBC18\uC73C\uB85C \uBCF4\uD5D8\uC0B0\uC5C5\uC758 \uD601\uC2E0\uC744 \uC774\uB8E8\uB294",
                        React.createElement("br", null),
                        " No 1. \uC778\uC288\uC5B4 \uD14C\uD06C \uAE30\uC5C5.")),
                React.createElement(KeyMapLayout, null,
                    React.createElement(KeyMapDescription, null,
                        "\uC2DC\uB3C4\uC640 \uC2E0\uB8B0\uB97C \uAC00\uCE58\uB85C \uB0B4\uAC78\uACE0 \uD601\uC2E0\uC744 \uC704\uD55C \uB04A\uC784\uC5C6\uB294 \uC2DC\uB3C4\uC640",
                        React.createElement("br", null),
                        " \uBCF4\uD5D8\uC0B0\uC5C5\uC5D0\uC11C\uC758 \uC2E0\uB8B0\uB97C \uB9CC\uB4E4\uC5B4\uAC00\uB294 \uC5ED\uD560.")),
                React.createElement(KeyMapLayout, null,
                    React.createElement(KeyMapDescription, null,
                        "\uADF8\uB9B0\uB9AC\uBCF8\uC744 \uD1B5\uD574 \uAE0D\uC815\uC801\uC774\uACE0 \uAC00\uCE58\uC788\uB294 \uACBD\uD5D8\uC744 \uC81C\uACF5\uD558\uBA70,",
                        React.createElement("br", null),
                        "\uBCF4\uD5D8\uC808\uCC28\uC758 \uAC04\uC18C\uD654\uC640 \uB514\uC9C0\uD138\uD654\uC758 \uBBF8\uB798\uC131\uC7A5\uC744 \uC120\uB3C4.")))))));
};
