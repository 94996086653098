import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useGetViewPort } from '~/hooks/useGetViewPort';
export const PageButton = styled('div')({
    position: 'relative',
    borderRadius: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '32px',
    letterSpacing: '-0.02em',
    textAlign: 'center',
    cursor: 'pointer',
    boxSizing: 'content-box',
    '@media screen and (max-width: 720px)': {
        width: '40px',
        height: '40px',
    },
});
export const ButtonTextBox = styled('span')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-2.5px, -9px)',
    lineHeight: '137%',
    fontSize: '16px',
    fontWeight: 700,
    letterSpacing: '-0.02em',
    textAlign: 'center',
    '@media screen and (max-width: 720px)': {
        width: '40px',
        height: '40px',
        transform: 'translate(-20px, -18px)',
    },
});
export const PagerButton = ({ index, onClick, active }) => {
    const [isHover, setIsHover] = useState(false);
    const [isMobile] = useGetViewPort();
    return (React.createElement(PageButton, { style: {
            color: active || isHover ? '#FFFFFF' : '#939393',
            backgroundColor: active || isHover ? '#29AD37' : '#FFFFFF',
        }, onClick: () => onClick(index), onMouseOver: () => setIsHover(true), onMouseLeave: () => setIsHover(false) },
        React.createElement(ButtonTextBox, null, index)));
};
