import { useCallback, useEffect, useState } from "react";
export function useGetViewPort() {
    const [viewport, setViewport] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setViewport(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const isMobile = useCallback(() => {
        return viewport < 720;
    }, [viewport]);
    return [isMobile(), viewport];
}
