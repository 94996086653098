import styled from '@emotion/styled';
export const HeaderContainer = styled('header')({
    width: '100%',
    height: '88px',
    position: 'fixed',
    zIndex: 999,
    '@media screen and (max-width: 720px)': {
        height: '56px',
    },
});
export const HeaderBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 160px',
    '@media screen and (max-width: 720px)': {
        padding: '16px 20px',
    },
});
export const HeaderLogo = styled('img')({
    width: '152px',
    height: '40px',
    '@media screen and (max-width: 720px)': {
        width: '123px',
        height: '24px',
    },
});
export const HeaderLinkSection = styled('div')({
    display: 'flex',
    gap: '40px',
    fontFamily: 'Pretendard',
    whiteSpace: 'nowrap',
    a: {
        color: '#B5B5B5',
        fontSize: '20px',
        lineHeight: '125%',
        fontWeight: 500,
        letterSpacing: '-0.035em',
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
    },
});
export const MobileMenuBox = styled('div')({
    width: '100vw',
    height: '100vh',
    backgroundColor: '#FFFFFF',
    position: 'fixed',
    top: 0,
});
export const MobileMenuBoxHeader = styled('div')({
    padding: '19px 21px',
    display: 'flex',
});
export const MobileMenuList = styled('div')({
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
});
export const MobileMenu = styled('a')({
    fontSize: '24px',
    lineHeight: '125%',
    letterSpacing: '-0.035em',
    color: '#000000',
    fontWeight: 700,
    cursor: 'pointer',
    textDecoration: 'none',
});
