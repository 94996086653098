import styled from '@emotion/styled';
export const CompanyNewsBox = styled('div')({
    '@media screen and (max-width: 720px)': {
        padding: '60px 0px 0px 0px',
    },
    display: 'flex',
    flexDirection: 'column',
    padding: '188px 0px 188px 0px',
    objectFit: 'contain',
    backgroundColor: '#fff',
    fontFamily: 'SpoqaHanSansNeo',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
});
export const CompanyNewsTitle = styled('span')({
    fontSize: 42,
    width: '80%',
    margin: '0 auto 100px auto',
    fontWeight: 'bold',
    letterSpacing: -0.4,
    textAlign: 'left',
    color: '#000',
});
export const CompanyNewsRow = styled('div')({
    width: '80%',
    margin: '0 auto 40px auto',
    fontSize: 24,
    '& a': {
        '@media screen and (max-width: 720px)': {
            fontSize: 16,
        },
        display: 'block',
        color: '#000',
        textAlign: 'left',
        marginBottom: 18,
    },
    '& span:nth-of-type(1)': {
        '@media screen and (max-width: 720px)': {
            fontSize: 16,
        },
    },
    '& span:nth-of-type(2)': {
        color: '#218a2c',
    },
    '& span:nth-of-type(4)': {
        '@media screen and (max-width: 720px)': {
            fontSize: 16,
        },
        color: '#777',
    },
    '& span:nth-of-type(3)': {
        '@media screen and (max-width: 720px)': {
            fontSize: 16,
        },
    },
});
export const Layout = styled('div')({
    padding: '160px 160px',
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    lineHeight: '137%',
    a: {
        cursor: 'pointer',
        color: 'inherit',
        textDecoration: 'none',
    },
    '@media screen and (max-width: 720px)': {
        padding: '80px 16px',
    },
});
export const Title = styled('h1')({
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: '137%',
    color: '#000000',
    margin: 0,
    span: {
        color: '#29AD37',
    },
    '@media screen and (max-width: 720px)': {
        fontSize: '24px',
    },
});
export const SponsorBox = styled('div')({
    padding: '80px 60px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '@media screen and (max-width: 720px)': {
        padding: '32px 16px',
        gap: '8px',
    },
});
export const SponsorLine = styled('div')({
    display: 'flex',
    justifyContent: 'space-evenly',
});
export const ArticleSection = styled('section')({
    margin: '80px 0 48px 96px',
    maxWidth: '928px',
    borderTop: '1px solid #F1F1F1',
    display: 'flex',
    flexDirection: 'column',
    '@media screen and (max-width: 720px)': {
        margin: '32px 16px',
    },
});
export const Article = styled('article')({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    justifyContent: 'center',
    borderBottom: '1px solid #F1F1F1',
    height: '131px',
    '@media screen and (max-width: 720px)': {
        height: '80px',
    },
});
export const ArticleTitle = styled('span')({
    fontSize: '24px',
    lineHeight: '137%',
    fontWeight: 700,
    letterSpacing: '-0.02em',
    '@media screen and (max-width: 720px)': {
        fontSize: '16px',
    },
});
export const ArticleOrigin = styled('span')({
    color: '#B5B5B5',
    fontSize: '16px',
    lineHeight: '137%',
    fontWeight: 400,
    letterSpacing: '-0.02em',
    '@media screen and (max-width: 720px)': {
        fontSize: '12px',
    },
});
export const Pager = styled('div')({
    display: 'flex',
    gap: 8,
    height: 'fit-content',
    justifyContent: 'center',
});
