import styled from '@emotion/styled';
export const Layout = styled('div')({
    padding: '84px 160px',
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    lineHeight: '137%',
    '@media screen and (max-width: 720px)': {
        padding: '64px 16px',
    },
});
export const Title = styled('h1')({
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: '137%',
    color: '#000000',
    margin: 0,
    span: {
        color: '#29AD37',
    },
    '@media screen and (max-width: 720px)': {
        fontSize: '24px',
    },
});
export const Panel = styled('div')({
    marginTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    '@media screen and (max-width: 720px)': {
        marginTop: '40px',
    },
});
export const PanelHeader = styled('div')({
    display: 'flex',
    gap: '43px',
    '@media screen and (max-width: 720px)': {
        gap: '30px',
    },
});
export const PanelContent = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    '@media screen and (max-width: 720px)': {
        flexDirection: 'column',
    },
});
export const PanelDescription = styled('div')({
    width: '448px',
    '@media screen and (max-width: 720px)': {
        width: '100%',
    },
});
export const PanelDescTitle = styled('span')({
    letterSpacing: '-0.05em',
    fontSize: '32px',
    lineHeight: '150%',
    fontWeight: 700,
    color: '#000000',
    '@media screen and (max-width: 720px)': {
        fontSize: '20px',
    },
});
export const PanelDescText = styled('span')({
    letterSpacing: '-0.05em',
    fontSize: '20px',
    lineHeight: '150%',
    fontWeight: 400,
    color: '#000000',
    '@media screen and (max-width: 720px)': {
        fontSize: '16px',
    },
});
