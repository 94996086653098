import React, { useEffect } from 'react';
import { useGetViewPort } from '~/hooks/useGetViewPort';
export function MapComponent() {
    const [isMobile, viewport] = useGetViewPort();
    useEffect(() => {
        const script = document.createElement('script');
        script.charset = 'UTF-8';
        script.src = 'https://ssl.daumcdn.net/dmaps/map_js_init/roughmapLoader.js';
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        };
    }, []);
    useEffect(() => {
        if (window.daum && window.daum.roughmap && window.daum.roughmap.Lander) {
            new window.daum.roughmap.Lander({
                timestamp: '1732759838652',
                key: '2mdzw',
                mapWidth: isMobile ? viewport - 32 : '448',
                mapHeight: '220',
            }).render();
            new window.daum.roughmap.Lander({
                timestamp: '1720598605669',
                key: '2kz55',
                mapWidth: isMobile ? viewport - 32 : '448',
                mapHeight: '220',
            }).render();
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: {
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
            } },
            React.createElement("span", { style: {
                    fontWeight: 400,
                    fontSize: isMobile ? '14px' : '16px',
                    lineHeight: '137%',
                    letterSpacing: '-0.02em',
                    color: '#B5B5B5',
                } }, "\uC11C\uC6B8\uC5F0\uAD6C\uC18C"),
            React.createElement("span", { style: {
                    fontWeight: 700,
                    fontSize: isMobile ? '16px' : '24px',
                    lineHeight: isMobile ? '137%' : '32px',
                    letterSpacing: '-0.9px',
                    color: '#000000',
                } },
                "\uC11C\uC6B8 \uC601\uB4F1\uD3EC\uAD6C \uC758\uC0AC\uB2F9\uB300\uB85C 83",
                React.createElement("br", null),
                "\uC624\uD22C\uD0C0\uC6CC 5\uCE35 \uADF8\uB9B0\uB9AC\uBCF8\uC5F0\uAD6C\uC18C"),
            React.createElement("span", { style: {
                    fontWeight: 700,
                    fontSize: isMobile ? '14px' : '16px',
                    lineHeight: '24px',
                    letterSpacing: '-0.4px',
                    color: '#777777',
                } }, "83, Uisadang-daero, Yeongdeungpo-gu, Seoul, Republic of Korea"),
            React.createElement("div", { className: "root_daum_roughmap root_daum_roughmap_landing", id: "daumRoughmapContainer1732759838652", style: {
                    marginTop: '24px',
                } })),
        React.createElement("div", { style: {
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
            } },
            React.createElement("span", { style: {
                    fontWeight: 400,
                    fontSize: isMobile ? '14px' : '16px',
                    lineHeight: '137%',
                    letterSpacing: '-0.02em',
                    color: '#B5B5B5',
                } }, "\uB300\uC804\uBCF8\uC0AC"),
            React.createElement("span", { style: {
                    fontWeight: 700,
                    fontSize: isMobile ? '16px' : '24px',
                    lineHeight: isMobile ? '137%' : '32px',
                    letterSpacing: '-0.9px',
                    color: '#000000',
                } }, "\uB300\uC804 \uC720\uC131\uAD6C \uB300\uD559\uB85C 157, \uB514-\uBE0C\uB9BF\uC9C0 \uB5141\uB3D9 \uACF5\uC720\uC624\uD53C\uC2A4-4 (\uC8FC)\uADF8\uB9B0\uB9AC\uBCF8"),
            React.createElement("span", { style: {
                    fontWeight: 700,
                    fontSize: isMobile ? '14px' : '16px',
                    lineHeight: '24px',
                    letterSpacing: '-0.4px',
                    color: '#777777',
                } }, "157 Daehak-ro, Yuseong-gu, Daejeon, Republic of Korea"),
            React.createElement("div", { className: "root_daum_roughmap root_daum_roughmap_landing", id: "daumRoughmapContainer1720598605669", style: {
                    marginTop: '24px',
                } }))));
}
export default MapComponent;
