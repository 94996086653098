import React from 'react';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        const defaultState = { hasError: true, error };
        return {
            ...defaultState,
            ...error,
        };
    }
    componentDidCatch(error, errorInfo) {
        console.warn(error, errorInfo);
    }
    render() {
        const { hasError, error } = this.state;
        const { children } = this.props;
        if (hasError && error !== undefined) {
            return React.createElement("div", null, error.message);
        }
        return children;
    }
}
export default ErrorBoundary;
