import styled from '@emotion/styled';
import React, { useState } from 'react';
export const StoreButtonLayout = styled('div')({
    width: '160px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    border: '1px solid #2E2C2F',
    '@media screen and (max-width: 720px)': {
        width: '150px',
    },
});
export function StoreButton({ storeType }) {
    const [isHover, setIsHover] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement("a", { href: storeType === 'google'
                ? 'https://play.google.com/store/apps/details?id=com.gribbon.android.rocketbosang&gl=KR'
                : 'https://apps.apple.com/kr/app/id1575683904/id1575683904', target: "_blank" },
            React.createElement(StoreButtonLayout, { style: {
                    backgroundColor: isHover ? '#F4F4F4' : '#FFFFFF',
                }, onMouseOver: () => setIsHover(true), onMouseLeave: () => setIsHover(false) },
                React.createElement("img", { src: '/img/main/tech/' + storeType + '_store.svg', alt: "store_button" })))));
}
