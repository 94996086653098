import styled from '@emotion/styled';
export const Layout = styled('div')({
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: '137%',
});
export const Title = styled('h1')({
    fontFamily: 'Pretendard',
    letterSpacing: '-0.02em',
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: '137%',
    color: '#000000',
    margin: 0,
    span: {
        color: '#29AD37',
    },
    '@media screen and (max-width: 720px)': {
        fontSize: '24px',
    },
});
export const Containter = styled('div')({
    padding: '0 152px ',
    '@media screen and (max-width: 720px)': {
        padding: '0 16px ',
    },
});
export const TopGradientSection = styled('div')({
    padding: '160px 152px 0 152px',
    height: '30vh',
    transition: 'background-color 1s ease',
});
export const BottomGradientSection = styled('div')({
    padding: '0 152px 160px 152px',
    height: '30vh',
});
export const ContentBox = styled('div')({
    padding: '120px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    position: 'relative',
    zIndex: 11,
    '@media screen and (max-width: 720px)': {
        padding: '66px 0',
        gap: '50px',
    },
});
export const CompanyGrowthTopBox = styled('div')({
    '@media screen and (max-width: 720px)': {
        padding: '60px 0px 0px 0px',
    },
    display: 'flex',
    flexDirection: 'column',
    padding: '188px 0px 100px 0px',
    objectFit: 'contain',
    backgroundColor: '#fff',
    fontFamily: 'SpoqaHanSansNeo',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
});
export const GraySpan = styled('span')({
    color: '#B5B5B5',
    letterSpacing: '-0.02em',
    lineHeight: '137%',
    fontSize: '24px',
    fontWeight: 500,
    '@media screen and (max-width: 720px)': {
        fontSize: '14px',
    },
});
export const MainSpan = styled('span')({
    display: 'flex',
    color: '#FFFFFF',
    letterSpacing: '-0.02em',
    lineHeight: '137%',
    fontSize: '72px',
    fontWeight: 700,
    '@media screen and (max-width: 720px)': {
        fontSize: '34px',
    },
});
export const BlackBox = styled('div')({
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#000000',
    right: 0,
});
export const CompanyGrowthBottomBox = styled('div')({
    '@media screen and (max-width: 720px)': {
        background: 'none',
        height: '400px',
    },
    width: '80%',
    height: '1377px',
    margin: '0 auto 0 auto',
    background: 'url(img/main/bg.png) repeat',
});
export const GrowthRow = styled('div')({
    '@media screen and (max-width: 720px)': {
        display: 'contents',
    },
    display: 'flex',
    '& div:nth-last-of-type(1)': {
        '@media screen and (max-width: 720px)': {
            marginBottom: '30px',
        },
        marginBottom: '130px',
    },
});
export const GrowthColumn = styled('div')({
    '@media screen and (max-width: 720px)': {
        margin: '0 10% 17px 10%',
        alignItems: 'center',
    },
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    margin: '0 200px 24px 0',
    '& span:nth-of-type(1)': {
        fontSize: 24,
        letterSpacing: -0.4,
        textAlign: 'left',
        color: '#777',
        marginBottom: 24,
    },
    '& span:nth-of-type(2)': {
        '@media screen and (max-width: 720px)': {
            fontSize: 20,
        },
        fontSize: 52,
        fontWeight: 'bold',
        letterSpacing: -0.4,
        textAlign: 'left',
        color: '#218a2c',
    },
});
