import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from '~/components/Footer';
import Header from '~/components/Header';
import PagesMain from '~/pages/Main';
const PagesRouter = () => {
    return (React.createElement(BrowserRouter, null,
        React.createElement(Header, null),
        React.createElement(Suspense, { fallback: React.createElement("div", null, "loading") },
            React.createElement(Routes, null,
                React.createElement(Route, { element: React.createElement(PagesMain, null), path: "" }),
                React.createElement(Route, { element: React.createElement("div", null, "\uD398\uC774\uC9C0\uB97C \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4."), path: "*" }))),
        React.createElement(Footer, null)));
};
export default PagesRouter;
